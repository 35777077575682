import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Mague, D. L. and Reissig, W. H. 1983. Phenology of San Jose scale
        (Homoptera: Diaspididae) in New York State apple orchards. Can. Entomol.
        115:717-722.
      </p>
    </>
  )
}
